<template>
  <div class="home">
    <img alt="Vue logo" src="img/msi_logo.svg">
    <p>
      This internal documentation for various services. Select the service from below to view the documentation.
    </p>
    <h3>General</h3>
    <ul>
      <li>
        <a href="/telemetry-api.html">Telemetry API</a>
      </li>
    </ul>
    <h3>DAQ</h3>
    <ul>
      <li>
        <a href="/daq-api.html">DAQ API</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Home'
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #F47216;
}

.home {
  margin-top: 60px;
}
</style>