import { createApp } from 'vue';
import FetchPlugin from '../plugins/FetchPlugin.js';
import KeycloakPlugin from '../plugins/KeycloakPlugin.js';
import initKeycloak from './keycloak.js';

export default async function createPage(component) {
  const kauth = new KeycloakPlugin({
    realm: 'meerkat',
    url: 'https://accounts.morgansolar.com/auth',
    clientId: 'internal-docs'
  });

  await initKeycloak(kauth);
  const app = createApp(component);
  app.use(kauth);
  app.use(new FetchPlugin(async (opt) => {
    const token = await kauth.getValidToken();
    const authHeaders = { Authorization: `Bearer ${token}` };
    opt.headers = { ...authHeaders, ...opt.headers };
    return opt;
  }));

  app.mount('#app');
}