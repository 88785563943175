import { fetch } from 'whatwg-fetch';

class FetchPlugin {
  constructor(requestIntercept = opt => opt) {
    this.requestIntercept = requestIntercept;
  }

  async get(url, options) {
    try {
      const res = await this.request('GET', url, options);
      if (res.status >= 200 && res.status <= 299) return res.json();
      throw new Error();
    } catch (e) {
      throw new Error(e);
    }
  }

  async request(method, url, options = {}) {
    options = await this.requestIntercept(options);
    const queryStr = options.query ? `?${(new URLSearchParams(options.query)).toString()}` : '';
    const fullUrl = `${url}${queryStr}`;
    options.method = method;
    if (options.body && (method !== 'GET' || method !== 'HEAD')) {
      options.headers['Content-Type'] = 'application/json; charset=utf-8';
      options.body = JSON.stringify(options.body);
    }

    return fetch(fullUrl, options);
  }

  install(app) {
    app.config.globalProperties.$fetch = this;
  }
}

export default FetchPlugin;