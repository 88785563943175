async function initKeycloak(kauth) {
  try {
    const loggedin = await kauth.init({
      onLoad: 'login-required',
      promiseType: 'native',
      checkLoginIframe: false
    });

    if (loggedin) {
      kauth.onRefreshTokenExpired = () => {
        kauth.login();
      };
    }
  } catch (err) {
    if (err instanceof TypeError) {
      if (err.message.includes('kc.login')) {
        // Ignore Keycloak bug
      } else {
        throw err;
      }
    } else {
      throw err;
    }
  }
}

export default initKeycloak;